var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-sidebar--single"},[_c('h3',{staticClass:"filter--title"},[_vm._v(_vm._s(_vm.$t('general.filter.propertyType')))]),_c('div',{staticClass:"property--type-filter"},_vm._l((_vm.propertyTypesByListingType),function(propertyType,index){return _c('div',{key:`property-type-${index}`,staticClass:"property--type",class:{
        selected:
          _vm.selectedPropertyTypes &&
          _vm.selectedPropertyTypes.find(function (item) {
            return item.id === propertyType.id;
          }),
      },style:({ display: propertyType.id == null ? 'none' : 'flex' }),on:{"click":function($event){return _vm.choose(propertyType)}}},[_c('div',[_vm._v(_vm._s(propertyType.name))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }