export default {
  data: () => ({
    isLoadingSearch: false,
  }),
  computed: {
    rentPeriod: {
      get() {
        return this.$store.state.v2.search.rentPeriod;
      },
      async set(val) {
        if (this.fromPage === 'HOME' || this.isMobileFilter) {
          this.$store.commit('v2/search/SET_RENT_PERIOD', val);
        } else {
          let query = await this.$store.dispatch('v2/search/setRentPeriod', {
            payload: val,
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      },
    },
    rentTypeId: {
      get() {
        return this.$store.state.v2.search.rentTypeId;
      },
      async set(val) {
        if (this.fromPage === 'HOME' || this.isMobileFilter) {
          this.$store.commit('v2/search/SET_RENT_TYPE_ID', val);
        } else {
          let query = await this.$store.dispatch('v2/search/setRentTypeId', {
            payload: val,
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      },
    },
    selectedPropertyType: {
      get() {
        return this.$store.state.v2.search.selectedPropertyType;
      },
      async set(val) {
        if (this.fromPage === 'HOME' || this.isMobileFilter) {
          this.$store.commit('v2/search/SET_SELECTED_PROPERTY_TYPE', val);
        } else {
          let query = await this.$store.dispatch('v2/search/setPropertyType', {
            payload: val,
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      },
    },
    projectStatus: {
      get() {
        return this.$store.state.v2.search.projectStatus;
      },
      set(val) {
        this.$store.commit('v2/search/SET_PROJECT_STATUS', val);
      },
    },
    category: {
      get() {
        return this.$store.state.v2.search.category;
      },
      set(val) {
        this.$store.commit('v2/search/SET_CATEGORY', val);
      },
    },
    route: {
      get() {
        return this.$store.state.route;
      },
      // eslint-disable-next-line no-unused-vars
      set(val) {},
    },
  },
  methods: {
    async buildingSizeMinSetter(value) {
      this.$store.commit('v2/search/SET_BUILDING_SIZE_MIN', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onBuildingSizeChange();
      }
    },
    async buildingSizeMaxSetter(value) {
      this.$store.commit('v2/search/SET_BUILDING_SIZE_MAX', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onBuildingSizeChange();
      }
    },
    async onBuildingSizeChange() {
      let buildingSizeMin = this.buildingSizeMin;
      let buildingSizeMax = this.buildingSizeMax;

      if (buildingSizeMin && buildingSizeMax && Number(buildingSizeMax) > Number(buildingSizeMin)) {
        if (this.formType !== 'home') {
          let query = await this.$store.dispatch('v2/search/onBuildingSizeChange', {
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      }
    },
    async sellPriceMinSetter(value) {
      this.$store.commit('v2/search/SET_SELL_PRICE_MIN', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onSellPriceChange();
      }
    },
    async sellPriceMaxSetter(value) {
      this.$store.commit('v2/search/SET_SELL_PRICE_MAX', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onSellPriceChange();
      }
    },
    async onSellPriceChange() {
      let sellPriceMin = this.sellPriceMin ? this.sellPriceMin.replace('Rp', '').trim() : '';
      let sellPriceMax = this.sellPriceMax ? this.sellPriceMax.replace('Rp', '').trim() : '';

      if (sellPriceMin && sellPriceMax && Number(sellPriceMax) > Number(sellPriceMin)) {
        if (this.formType !== 'home') {
          let query = await this.$store.dispatch('v2/search/onSellPriceChange', {
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      }
    },
    async rentPriceMinSetter(value) {
      this.$store.commit('v2/search/SET_RENT_PRICE_MIN', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onRentPriceChange();
      }
    },
    async rentPriceMaxSetter(value) {
      this.$store.commit('v2/search/SET_RENT_PRICE_MAX', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onRentPriceChange();
      }
    },
    async onRentPriceChange() {
      let rentPriceMin = this.rentPriceMin ? this.rentPriceMin.replace('Rp', '').trim() : '';
      let rentPriceMax = this.rentPriceMax ? this.rentPriceMax.replace('Rp', '').trim() : '';

      if (rentPriceMin && rentPriceMax && Number(rentPriceMax) > Number(rentPriceMin)) {
        if (this.formType !== 'home') {
          let query = await this.$store.dispatch('v2/search/onRentPriceChange', {
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      }
    },
    async updateRoute(query) {
      // Check apakah ganti filter-nya dari sidebar atau bukan, kalau dari sidebar jangan langsung redirect halaman
      if (query && !this.$store.state.v2.search.activeFilterSidebar) {
        try {
          this.$store.commit('v2/search/SET_IS_LOADING', true);
          if (this.$route.name === 'search') {
            await this.$store.dispatch('v2/search/goToSearchPage', {
              router: this.$router,
              query: query,
              params: this.$route.params,
            });
          } else {
            await this.$router.push({ query: query }).catch(async (e) => {
              console.log('ERROR updateRoute (router.push) in searchMixin.js: ', e);
            });
          }
        } catch (e) {
          console.log('ERROR updateRoute in searchMixin.js: ', e);
        } finally {
          this.$store.commit('v2/search/SET_IS_LOADING', false);
        }
      }
    },
  },
};
