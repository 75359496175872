<template>
  <section class="search--filters">
    <search-profile
      class="hide--in-mobile"
      v-if="$route.name === 'search-user-listing' || $route.name === 'search-agent-catalog'"
    ></search-profile>
    <div class="hide--in-desktop hide--in-tablet">
      <div class="title--text">
        <div class="back--button hide--in-desktop hide--in-tablet" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        <div class="d-flex justify-space-between align-items-center flex-grow-1">
          <div>
            {{
              isSecondary ? $t('general.filter.propertyFilter') : $t('general.filter.projectFilter')
            }}
          </div>
          <div class="clear--filter" @click="clearFilters()">
            {{ $t('general.filter.clearAll') }}
          </div>
        </div>
      </div>
    </div>
    <div class="filter--footer hide--in-desktop hide--in-tablet" :class="{ active: isActive }">
      <button class="btn btn-primary" @click="applyFilters()">
        {{ $t('general.filter.apply') }}
      </button>
    </div>
    <div
      class="filter--sidebar-content"
      v-if="$route.name !== 'search-user-listing' && $route.name !== 'search-agent-catalog'"
    >
      <div class="hide--in-mobile">
        <div class="d-flex justify-content-between align-items-center">
          <div class="filter--title">
            {{
              isSecondary ? $t('general.filter.propertyFilter') : $t('general.filter.projectFilter')
            }}
          </div>
          <div
            class="clear--filter"
            style="padding-left: 12px; margin-bottom: 12px"
            @click="clearFilters()"
          >
            {{ $t('general.filter.clearAll') }}
          </div>
        </div>
      </div>
      <property-type></property-type>
      <bedroom></bedroom>
      <sell-price></sell-price>
      <div class="hide--in-mobile">
        <button class="btn btn-primary w-100" type="button" @click="applyFilters">
          {{ $t('general.filter.apply') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SearchProfile from '@/components/search/search-profile';
import PropertyType from '@/components/search/partials/filters-sidebar/property-type';
import Bedroom from '@/components/search/partials/filters-sidebar/bedroom';
import SellPrice from '@/components/search/partials/filters-sidebar/sell-price';
export default {
  name: 'search-filters',
  components: { SearchProfile, PropertyType, Bedroom, SellPrice },
  data: () => ({}),
  computed: {
    ...mapState({
      isActive: (state) => state.v2.search.activeFilterSidebar,
      selectedListingType: (state) => state.v2.search.selectedListingType,
    }),
    ...mapGetters({
      isSecondary: 'v2/search/isSecondary',
    }),
  },
  methods: {
    close() {
      this.$store.commit('v2/search/SET_ACTIVE_FILTER_SIDEBAR', false);
    },
    clearFilters() {
      this.$store.commit('v2/search/CLEAR_FILTER');
    },
    applyFilters() {
      this.close();
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
  },
};
</script>
