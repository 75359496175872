<template>
  <div class="search-sidebar--single">
    <h3 class="filter--title">{{ $t('general.filter.propertyType') }}</h3>
    <!--    <v-select-->
    <!--      v-model="selectedPropertyType"-->
    <!--      :items="propertyTypes"-->
    <!--      :label="$t('general.filter.propertyType')"-->
    <!--      item-value="id"-->
    <!--      item-text="name"-->
    <!--      solo-->
    <!--      hide-details-->
    <!--      return-object-->
    <!--    ></v-select>-->
    <div class="property--type-filter">
      <div
        class="property--type"
        :class="{
          selected:
            selectedPropertyTypes &&
            selectedPropertyTypes.find(function (item) {
              return item.id === propertyType.id;
            }),
        }"
        :style="{ display: propertyType.id == null ? 'none' : 'flex' }"
        @click="choose(propertyType)"
        v-for="(propertyType, index) in propertyTypesByListingType"
        :key="`property-type-${index}`"
      >
        <!--        <img-->
        <!--          src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/property-type/home.svg"-->
        <!--          v-if="propertyType.id === 1"-->
        <!--        />-->
        <!--        <img-->
        <!--          src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/property-type/condo.svg"-->
        <!--          v-if="propertyType.id === 2"-->
        <!--        />-->
        <div>{{ propertyType.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchMixin from '@/mixins/searchMixin';
import LodashMixin from '@/mixins/lodash';
import { mapGetters, mapState } from 'vuex';

export default {
  mixins: [SearchMixin, LodashMixin],
  components: {},
  computed: {
    ...mapState({
      isLoading: (state) => state.v2.search.isLoading,
      propertyTypes: (state) => state.v2.masters.propertyTypes,
    }),
    ...mapGetters({
      propertyTypesByListingType: 'v2/masters/propertyTypesByListingType',
    }),
    selectedPropertyTypes: {
      get() {
        return this.$store.state.v2.search.selectedPropertyTypes;
      },
      set(value) {
        this.$store.dispatch('v2/search/setPropertyTypes', {
          payload: value,
          query: this.$route.query,
        });
      },
    },
  },
  data: () => ({}),
  methods: {
    choose(propertyType) {
      if (
        this.selectedPropertyTypes &&
        this.selectedPropertyTypes.find(function (item) {
          return item.id === propertyType.id;
        })
      ) {
        this.selectedPropertyTypes = this.selectedPropertyTypes.filter(
          (item) => item.id !== propertyType.id,
        );
      } else {
        this.selectedPropertyTypes.push(propertyType);
      }

      // APPLY FILTER ON SELECT PROPERTY TYPE
      if (this.$mq !== 'xs') {
        this.$store.dispatch('v2/search/goToSearchPage', {
          router: this.$router,
          params: this.$route.params,
        });
      }
    },
  },
};
</script>
