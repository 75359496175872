<template>
  <section class="search--profile" v-if="owner">
    <div class="profile--bg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="378"
        height="52"
        viewBox="0 0 378 52"
        fill="none"
      >
        <path
          d="M139.609 7.83073C116.759 12.2019 32.9455 16.9063 -2.12241 13.9454L-2.61392 54.6022L380.948 65.98C391.859 55.457 407.25 31.2455 381.521 18.5834C349.36 2.7558 215.272 -6.64389 139.609 7.83073Z"
          fill="#EFCECF"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="378"
        height="43"
        viewBox="0 0 378 43"
        fill="none"
      >
        <path
          d="M136.394 10.4774C113.663 15.425 29.9947 22.2477 -5.13686 20.1747L-4.59994 60.8308L379.127 62.5042C389.769 51.7086 404.542 27.1156 378.501 15.1083C345.95 0.0991242 211.667 -5.90628 136.394 10.4774Z"
          fill="#F2D5D7"
        />
      </svg>
    </div>
    <div class="d-flex justify-content-center">
      <div class="d-flex align-items-center">
        <img
          class="profile--picture"
          :src="!owner ? '/img/placeholder.png' : owner.photo_profile_user"
        />
      </div>
    </div>
    <div class="profile--content">
      <div class="name">
        {{ owner.name }}
      </div>
      <!--      <div class="agent&#45;&#45;type mt-2" v-if="owner.agent_type">-->
      <!--        {{ owner.agent_type }}-->
      <!--      </div>-->
      <div class="agent--type mt-2" v-if="owner.nib">
        {{ `${$t('general.nib')}: ${owner.nib}` }}
      </div>
      <div
        class="d-flex align-items-center justify-content-center mt-2 mb-2"
        v-if="owner.agent_office_logo || owner.agent_type_id === 1"
      >
        <div class="agent--type agent--type-office" v-if="owner.agent_type">
          {{ owner.agent_type }}
        </div>
        <!--        <a-->
        <!--          href=""-->
        <!--          :title="owner.agent_type_id === 1 ? owner.agent_type : owner.agent_office"-->
        <!--        >-->
        <div class="agent--office">
          <div class="agent--office-logo" v-if="owner.agent_office_logo">
            <img :src="owner.agent_office_logo" :alt="owner.agent_office" />
          </div>
          <div class="agent--office-logo" v-else-if="owner.agent_type_id === 1">
            <img :src="agentOfficeIndependent" :alt="owner.agent_type" />
          </div>
          <!--          <div>-->
          <!--            {{ owner.agent_office }}-->
          <!--          </div>-->
        </div>
        <!--        </a>-->
      </div>
      <div v-else>
        <div class="agent--type mt-2 mb-2" v-if="owner.agent_type">
          {{ owner.agent_type }}
        </div>
      </div>
      <div class="area--spec" v-if="owner.social_medias && owner.social_medias.length > 0">
        <div v-for="(socmed, index) in owner.social_medias" :key="`social-media-${index}`">
          <a :href="socmed.url" target="_blank" class="social--media">
            <img
              src="@/assets/img/icons/instagram-2.svg"
              v-if="socmed.name === 'instagram'"
              alt="Instagram"
            />
            <img
              src="@/assets/img/icons/facebook-2.svg"
              v-else-if="socmed.name === 'facebook'"
              alt="Facebook"
            />
            <img
              src="@/assets/img/icons/tiktok-2.svg"
              v-else-if="socmed.name === 'tiktok'"
              alt="Tiktok"
            />
            <div>{{ socmed.name[0].toUpperCase() + socmed.name.substring(1) }}</div>
          </a>
        </div>
      </div>
      <div
        class="property--spec"
        v-if="owner.property_specializations && owner.property_specializations.length > 0"
      >
        <div class="spec--title">
          {{ $t('general.propertySpecialization') }}
        </div>
        <div
          class="property--spec-text"
          v-for="(spec, index) in owner.property_specializations"
          :key="`property-spec-${index}`"
        >
          {{ spec }}
        </div>
      </div>
      <div
        class="area--spec"
        v-if="owner.area_specializations && owner.area_specializations.length > 0"
      >
        <div class="spec--title">
          {{ $t('general.areaSpecialization') }}
        </div>
        <div
          class="area--spec-text"
          v-for="(spec, index) in owner.area_specializations"
          :key="`area-spec-${index}`"
        >
          {{ spec }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'search-profile',
  computed: {
    ...mapState({
      agentOfficeIndependent: (state) => state.global.agentOfficeIndependent,
      owner: (state) => state.v2.profile.owner,
    }),
  },
};
</script>
