<template>
  <div class="search-sidebar--single">
    <h3 class="filter--title">{{ $t('general.filter.bedroom') }}</h3>
    <v-select
      v-model="minBedroom"
      :items="minBedrooms"
      :label="$t('general.filter.minBedroom')"
      item-value="id"
      item-text="name"
      solo
      hide-details
      return-object
    >
      <template v-slot:selection="{ item, index }">
        {{ item.name }}
      </template>
    </v-select>
  </div>
</template>

<script>
import SearchMixin from '@/mixins/searchMixin';
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';

export default {
  mixins: [SearchMixin, LodashMixin],
  components: {},
  computed: {
    ...mapState({
      isLoading: (state) => state.v2.search.isLoading,
      minBedrooms: (state) => state.v2.search.minBedrooms,
    }),
    minBedroom: {
      get() {
        return this.$store.state.v2.search.minBedroom;
      },
      set(value) {
        this.$store.commit('v2/search/SET_MIN_BEDROOM', value);
      },
    },
  },
  data: () => ({}),
  methods: {},
};
</script>
