<template>
  <div class="search-sidebar--single">
    <h3 class="filter--title">{{ $t('general.filter.price') }}</h3>
    <div class="d-flex align-items-center flex-wrap">
      <cleave
        v-model="sellPriceMin"
        :placeholder="$t('general.filter.minPrice')"
        :options="cleaveOptionsPrice"
        class="basic--input mb-4"
        name="sellPriceMin"
        :readonly="isLoading"
      ></cleave>
      <cleave
        v-model="sellPriceMax"
        :placeholder="$t('general.filter.maxPrice')"
        :options="cleaveOptionsPrice"
        class="basic--input"
        name="sellPriceMax"
        :readonly="isLoading"
      ></cleave>
    </div>
    <!--    <div class="clear&#45;&#45;price-filter" @click="clearPriceFilter">-->
    <!--      <v-icon class="mr-2">mdi-delete</v-icon>-->
    <!--      {{ $t('general.filter.removePriceFilter') }}-->
    <!--    </div>-->
  </div>
</template>

<script>
import SearchMixin from '@/mixins/searchMixin';
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';
const Cleave = () => import('vue-cleave-component');

export default {
  mixins: [SearchMixin, LodashMixin],
  components: {
    Cleave,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.v2.search.isLoading,
    }),
    sellPriceMin: {
      get() {
        return this.$store.state.v2.search.sellPriceMin;
      },
      set(value) {
        this.$store.commit('v2/search/SET_SELL_PRICE_MIN', value);
      },
    },
    sellPriceMax: {
      get() {
        return this.$store.state.v2.search.sellPriceMax;
      },
      set(value) {
        this.$store.commit('v2/search/SET_SELL_PRICE_MAX', value);
      },
    },
  },
  data: () => ({
    cleaveOptionsPrice: {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      prefix: 'Rp ',
      noImmediatePrefix: true,
    },
  }),
  methods: {
    clearPriceFilter() {
      this.sellPriceMin = null;
      this.sellPriceMax = null;
    },
  },
};
</script>
